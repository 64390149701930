import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="fixed bottom-0 w-full">
      <div className="bg-white border-t border-gray-200 text-center py-4">
        <Link to="/terms" className="text-sm text-zinc-500 hover:text-zinc-400 px-4">Terms of Use</Link>
        <span className="text-sm text-gray-500">|</span>
        <Link to="/privacy" className="text-sm text-zinc-500 hover:text-zinc-400 px-4">Privacy Policy</Link>
      </div>
    </div>
  );
};

export default Footer;
