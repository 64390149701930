// Landing.js
import React from 'react';

const Landing = () => {
  return (
    <div className="flex flex-col items-center w-full min-h-screen bg-blue-100 pt-16"> {/* Add padding equal to the height of the header */}
      <div className="w-full max-w-md mt-10">
        <div className="bg-white rounded px-8 pt-6 pb-8 mb-4">
          {/* Rest of your landing page content */}
          <p className="mb-4">Welcome to the landing page!</p>
          {/* You might want to remove the sign-out button from here if it's in the Header */}
        </div>
      </div>
    </div>
  );
};

export default Landing;
