// Header.js
import React, { useState } from 'react';

const Header = ({ user, signOut }) => {
  const [iconState, setIconState] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleIconClick = () => {
    if (iconState) {
      setIconState(false);
      setShowDropdown(!showDropdown); // Toggle dropdown visibility without spinning for 'X'
    } else {
      setIsSpinning(true);
      setTimeout(() => {
        setIsSpinning(false);
        setIconState(true);
        setShowDropdown(!showDropdown); // Toggle dropdown visibility with spinning for leaf
      }, 500);
    }
  };

  return (
    <div className="fixed top-0 w-full flex justify-between items-center py-4 px-8 z-50 bg-transparent">
      <div className="relative flex items-center">
        <button onClick={handleIconClick} className={`focus:outline-none ${isSpinning ? 'spin' : ''}`}>
          {iconState ? (
            <svg className="w-8 h-8 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <img src="/mongodb-leaf_128x128.png" alt="MongoDB Atlas" className="w-8 h-auto" />
          )}
        </button>
        {/* Dropdown Menu */}
        {showDropdown && (
          <div className="absolute top-full mt-2 py-2 w-48 bg-white shadow-xl rounded-md">
            <a href="#" className="block px-4 py-2 text-black hover:bg-gray-100">Menu Item 1</a>
            <a href="#" className="block px-4 py-2 text-black hover:bg-gray-100">Menu Item 2</a>
            <a href="#" className="block px-4 py-2 text-black hover:bg-gray-100">Menu Item 3</a>
          </div>
        )}
      </div>
      <div>
        <button
          onClick={signOut}
          className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default Header;
